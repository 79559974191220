import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./main.css";
import Vue3Lottie from "vue3-lottie";
import VueSocials from 'vue-socials';
// import { createMetaManager } from 'vue-meta'
// import { plugin as vueMetaPlugin } from 'vue-meta'
const app = createApp(App);

app.use(store).use(router);
app.use(Vue3Lottie)
app.use(VueSocials)
// app.use(createMetaManager())
// app.use(vueMetaPlugin)

app.mount("#app");