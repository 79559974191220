import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Achievements.vue"),
  },
  {
    path: "/achievements",
    name: "Achievements",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Achievements.vue"),
  },
  {
    path: "/achievements/new",
    name: "NewAchievement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewAchievement.vue"),
  },
  {
    path: "/certificate",
    name: "Certificate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Certificate.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
